import { MainBtn } from "../../../components/global/btns/btns";
import { Heading } from "../../../components/global/heading/heading";
import { Paragraph } from "../../../components/global/paragraph/paragraph";
//importing assets
import Pic from "../../../assets/images/begin1.svg";
import { useTranslation } from "react-i18next";

export const Begin = () => {
  const {t} = useTranslation()
  return (
    <div className="container">
      <div className="section ">
        <div className="section__side">
          <Heading title={t("about.ourBeginnings")} size={2} />
          <Paragraph>
          {t("about.desBeginnings")}
          </Paragraph>
        </div>
        <div className="section__side">
          <img src={Pic} alt="man" className="section__img" />
        </div>
      </div>
    </div>
  );
};
