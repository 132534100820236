import { useTranslation } from "react-i18next";
import { Heading } from "../../../components/global/heading/heading";
import { Paragraph } from "../../../components/global/paragraph/paragraph";
//assets
import { SVG } from "../../../components/global/svg/svg";



export const Stats = () => {
  const {t}=useTranslation()
  const data = [
    {
      icon: "customers",
      title: t("about.happyCustomers"),
      number: "+750",
    },
    {
      icon: "plus-person",
      title: t("about.accountNumber"),
      number: "+2,200",
    },
    {
      icon: "income",
      title: t("about.finishedProjects"),
      number: "2 K",
    },
    {
      icon: "cup",
      title: t("about.happyCustomers"),
      number: "+750",
    },
  ];

  return (
    <div className="stats">
      <Heading title={t("about.ourNumbers")} size={2} />
      <div className="mt--large">
        <div className="stats__grid">
          {" "}
          {data.map(({ icon, number, title }, key) => (
            <StatsCard icon={icon} number={number} title={title} key={key} />
          ))}
        </div>
      </div>
    </div>
  );
};

const StatsCard = ({ icon, number, title }) => {
  return (
    <div className="stats-card">
      <SVG name={icon} />
      <div className="stats-card__number">{number}</div>
      <Paragraph className="stats-card__title">{title}</Paragraph>
    </div>
  );
};
