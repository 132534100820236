export const IconContactus = ({ name }) => {
  switch (name) {
    case "email":
      return (
        <svg
          width="25"
          height="18"
          viewBox="0 0 25 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M25 4.90549V13.5731C25.0001 14.4569 24.6031 15.3071 23.8904 15.9495C23.1778 16.5918 22.2035 16.9776 21.1675 17.0276L20.9375 17.033H4.0625C3.02483 17.033 2.02646 16.6949 1.27221 16.088C0.517958 15.4811 0.0649983 14.6513 0.00625014 13.769L0 13.5731V4.90549L12.065 10.2879C12.1992 10.3478 12.3485 10.3791 12.5 10.3791C12.6515 10.3791 12.8008 10.3478 12.935 10.2879L25 4.90549ZM4.0625 2.5129e-08H20.9375C21.9445 -0.000103278 22.9156 0.318299 23.6625 0.893451C24.4095 1.4686 24.879 2.25951 24.98 3.11277L12.5 8.68043L0.02 3.11277C0.116904 2.2934 0.553812 1.53032 1.25219 0.960726C1.95056 0.391129 2.8646 0.0523625 3.83 0.00532295L4.0625 2.5129e-08H20.9375H4.0625Z"
            fill="#E53516"
          />
        </svg>
      );

    case "phone":
      return (
        <svg
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.50327 7.03325L5.35522 9.1617C5.64814 10.2218 6.42111 11.5307 7.67414 13.0883C8.92717 14.6459 10.3307 16.0526 11.8848 17.3085C13.4389 18.5644 14.7529 19.3391 15.827 19.6327L17.9506 17.5042C18.1947 17.2596 18.6259 17.1576 19.2443 17.1984C19.8627 17.2392 20.4851 17.337 21.1116 17.492C21.7382 17.6469 22.2548 17.8223 22.6617 18.018C23.573 18.4583 24.2401 19.0007 24.6632 19.6449C25.0863 20.2891 25.1108 20.7907 24.7365 21.1495L22.1246 23.7672C21.4249 24.4849 20.4729 24.8885 19.2687 24.9782C18.0645 25.0679 16.7627 24.8804 15.3632 24.4155C13.9637 23.9507 12.4828 23.2127 10.9206 22.2015C9.3584 21.1903 7.86942 19.9752 6.45366 18.5562C5.0379 17.1372 3.82148 15.6408 2.80442 14.0669C1.78735 12.493 1.04692 11.0047 0.583139 9.60207C0.119356 8.19941 -0.0677846 6.8987 0.0217174 5.69992C0.111219 4.50114 0.513978 3.55109 1.22999 2.84976L3.84183 0.232022C4.18356 -0.110486 4.63107 -0.0737892 5.18436 0.342114C5.73764 0.758016 6.33161 1.41449 6.96626 2.31154C7.27545 2.7519 7.51954 3.54293 7.69855 4.68463C7.87755 5.82632 7.81246 6.6092 7.50327 7.03325Z"
            fill="#E53516"
          />
        </svg>
      );

    case "location":
      return (
        <svg
          width="18"
          height="29"
          viewBox="0 0 18 29"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.99618 8.75337e-10C6.61746 0.00230044 4.33618 1.09442 2.6506 3.03783C0.965012 4.98125 0.0121046 7.61802 0 10.3722C0 14.5017 2.00085 17.9591 3.46712 20.4792L3.73441 20.939C5.19325 23.3872 6.75826 25.7482 8.42342 28.013L9.00382 28.8L9.58422 28.013C11.2492 25.748 12.8142 23.387 14.2732 20.939L14.5405 20.4704C15.9992 17.9503 18 14.5017 18 10.3722C17.9879 7.61649 17.0339 4.97838 15.3467 3.03472C13.6595 1.09106 11.3762 -3.56848e-05 8.99618 8.75337e-10ZM8.99618 15.0322C8.00535 15.0322 7.03676 14.692 6.21291 14.0546C5.38906 13.4173 4.74694 12.5113 4.36777 11.4514C3.98859 10.3914 3.88938 9.22513 4.08268 8.09991C4.27599 6.97469 4.75312 5.9411 5.45375 5.12987C6.15437 4.31863 7.04703 3.76617 8.01883 3.54235C8.99062 3.31853 9.99792 3.4334 10.9133 3.87244C11.8287 4.31148 12.6112 5.05496 13.1616 6.00888C13.7121 6.9628 14.0059 8.0843 14.0059 9.23156C14.0059 10.77 13.4781 12.2454 12.5386 13.3333C11.5991 14.4211 10.3249 15.0322 8.99618 15.0322Z"
            fill="#E53516"
          />
          <path
            d="M8.99502 12.5296C10.5682 12.5296 11.8436 11.0529 11.8436 9.23136C11.8436 7.40978 10.5682 5.93311 8.99502 5.93311C7.42182 5.93311 6.14648 7.40978 6.14648 9.23136C6.14648 11.0529 7.42182 12.5296 8.99502 12.5296Z"
            fill="#E53516"
          />
        </svg>
      );

    default:
      return;
  }
};
