import { useEffect, useState } from "react";
import { SecondaryBtn } from "../../../components/global/btns/btns";
import { EmployeeCard } from "../../../components/global/employe-card/employe-card";
import { Heading } from "../../../components/global/heading/heading";
import { Paragraph } from "../../../components/global/paragraph/paragraph";
import { itTeam, managementTeam, marketingTeam } from "../../../data/team";
import { useTranslation } from "react-i18next";
import { useGetAllTeamQuery } from "../../../RTQ/services/allApi";

export const Team = () => {
  const { data, isSuccess } = useGetAllTeamQuery();
  const [team, setTeam] = useState(data);
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const returnLang = (en, ar) => {
    if (lang === "en") {
      return en;
    } else {
      return ar;
    }
  };
  const uniqueCat = [
    ...new Map(data?.map((item) => [item["mainSectionAr"], item])).values(),
  ];

  const [active, setActive] = useState("");
  console.log(uniqueCat);
  useEffect(() => {
    if (isSuccess) {
      const val = returnLang(
        uniqueCat[0]?.mainSectionEn,
        uniqueCat[0]?.mainSectionAr
      );
      setActive(val);
      setTeam(data);
    }
  }, [isSuccess, lang]);

  console.log(team);
  return (
    <div className="team-container">
      <div className="flex--col work__section mt--large">
        <Heading title={t("nav.ourteam")} size={2} />
        <Paragraph>{t("home.desourWork")} </Paragraph>
        <div className="flex--row work__btns">
          {uniqueCat?.map((item, key) => (
            <SecondaryBtn
              key={key}
              value={returnLang(item?.mainSectionEn, item?.mainSectionAr)}
              name={returnLang(item?.mainSectionEn, item?.mainSectionAr)}
              active={active}
              action={() => {
                setActive(returnLang(item?.mainSectionEn, item?.mainSectionAr));
                const filterTeam = data.filter(
                  (filterItem) =>
                    returnLang(
                      filterItem[0]?.mainSectionEn,
                      filterItem[0]?.mainSectionAr
                    ) ===
                    returnLang(item[0]?.mainSectionEn, item[0]?.mainSectionAr)
                );
                setTeam(filterTeam);
              }}
            />
          ))}
        </div>
        <div className="team__grid team__section mt--large">
          {team
            ?.filter(
              (fill) =>
                returnLang(fill?.mainSectionEn, fill?.mainSectionAr) === active
            )
            ?.map((item, key) => {
              return <EmployeeCard item={item} key={key} />;
            })}
        </div>{" "}
      </div>
    </div>
  );
};
const category = [
  {
    name: "management",
    value: "Management",
    team: managementTeam,
  },
  {
    name: "Development",
    value: "development",
    team: itTeam,
  },
  // {
  //   name: "brand",
  //   value: "Branding",
  //   team: [],
  // },
  {
    name: "social",
    value: "Social media",
    team: marketingTeam,
  },
];
