import { useState } from "react";
import { MainBtn, ThirdBtn } from "../../components/global/btns/btns";
import { Heading } from "../../components/global/heading/heading";
import { Paragraph } from "../../components/global/paragraph/paragraph";
import { useTranslation } from "react-i18next";

export const JoinUS = () => {
  const { t } = useTranslation();
  return (
    <div className="join-us">
      <div className="container">
        <Heading title={t("glopal.joinTeam")} size={2} />
        <div className="m--large flex--row algin-items--start ">
          {opportunities.map((item, key) => (
            <InternShip item={item} key={key} />
          ))}
        </div>
      </div>
    </div>
  );
};

const InternShip = ({ item }) => {
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!open);
  };
  const { t } = useTranslation();


  return (
    <div className={`internship m--small`} onClick={handleClick}>
      <div
        className={`internship__label-box  ${
          open ? "internship__label-box-active" : ""
        }`}
      >
        <div className={`internship__label `}>{item.seniority}</div>
        <Arrow rotate={open} />
      </div>
      {open && (
        <div className="internship__details m--small">
          <ThirdBtn value={item.type} />
          <h2 className="heading heading--4">Seniority :{item.seniority}</h2>
          <h2 className="heading heading--4">Role </h2>
          <Paragraph>{item.role}</Paragraph>
          <h2 className="heading heading--4"> Qualifications & Experience</h2>
          {item.exp.map((item, key) => (
            <Paragraph key={key}>{item}</Paragraph>
          ))}
          <MainBtn value={t("glopal.apply")} path="/contact-us" />
        </div>
      )}
    </div>
  );
};

const Arrow = ({ rotate, onClick }) => {
  return (
    <svg
      onClick={onClick}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      className={rotate ? "rotation " : ""}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 22L24 30L32 22"
        stroke="#383838"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const opportunities = [
  {
    type: "Internship",
    seniority: " 4th and 5th year students.",
    role: "Seize the opportunity and join our team as a Front-end Developer Trainee.",
    exp: [
      "Good knowledge of CSS, HTML, JavaScript & Typescript.",
      "Good experience in Angular8+, Vue.js, jQuery.",
      "Good with Angular and RESTful API.",
      "Working knowledge of CSS preprocessors Sass,LESS.",
      "Solid knowledge of source management through Git. ",
      " Willing to learn new technologies and improve technical skills.",
      "Professional, precise and has great communication skills.",
    ],
  },
  {
    type: "Internship",
    seniority: " software tester",
    role: "Seize the opportunity and join our team as a Front-end Developer Trainee.",
    exp: [
      "Good knowledge of CSS, HTML, JavaScript & Typescript.",
      "Good experience in Angular8+, Vue.js, jQuery.",
      "Good with Angular and RESTful API.",
      "Working knowledge of CSS preprocessors Sass,LESS.",
      "Solid knowledge of source management through Git. ",
      " Willing to learn new technologies and improve technical skills.",
      "Professional, precise and has great communication skills.",
    ],
  },
  {
    type: "Internship",
    seniority: " Front-end Developer – Angular ",
    role: "Seize the opportunity and join our team as a Front-end Developer Trainee.",
    exp: [
      "Good knowledge of CSS, HTML, JavaScript & Typescript.",
      "Good experience in Angular8+, Vue.js, jQuery.",
      "Good with Angular and RESTful API.",
      "Working knowledge of CSS preprocessors Sass,LESS.",
      "Solid knowledge of source management through Git. ",
      " Willing to learn new technologies and improve technical skills.",
      "Professional, precise and has great communication skills.",
    ],
  },
  {
    type: "Internship",
    seniority: "react native developer",
    role: "Seize the opportunity and join our team as a Senior Back-end Developer .",
    exp: [
      "3+ years of relevant work experience.",
      "Strong knowledge of PHP web frameworks Laravel, Lumen is plus.",
      "MySQL knowledge.",
      "Knowledge of web sockets.",
      "Knowledge of git..",
      "Knowledge of MVC design patterns.",
      "Knowledge the fully synchronous behavior of PHP.",
      "Knowledge of Restful Api.",
      "Professional, precise and has great communication skills.",
      "Excellent Problem Solving Skills",
    ],
  },
  {
    type: "Internship",
    seniority: "graphic designer  – Internship",
    role: "Seize the opportunity and join our team as a Senior Back-end Developer .",
    exp: [
      "3+ years of relevant work experience.",
      "Strong knowledge of PHP web frameworks Laravel, Lumen is plus.",
      "MySQL knowledge.",
      "Knowledge of web sockets.",
      "Knowledge of git..",
      "Knowledge of MVC design patterns.",
      "Knowledge the fully synchronous behavior of PHP.",
      "Knowledge of Restful Api.",
      "Professional, precise and has great communication skills.",
      "Excellent Problem Solving Skills",
    ],
  },
  {
    type: "Full Time",
    seniority: "graphic designer  – Branding ",
    role: "We’re always looking for great talent. If you know what you’re doing and love doing it, check out our open positions and apply today!",
    exp: [
      "3+ years of relevant work experience.",
      "Strong knowledge of PHP web frameworks Laravel, Lumen is plus.",
      "MySQL knowledge.",
      "Knowledge of web sockets.",
      "Knowledge of git..",
      "Knowledge of MVC design patterns.",
      "Knowledge the fully synchronous behavior of PHP.",
      "Knowledge of Restful Api.",
      "Professional, precise and has great communication skills.",
      "Excellent Problem Solving Skills",
    ],
  },
];
