import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAddEmailInfoMutation } from "../../../RTQ/services/allApi";

export const Form = () => {
  const [addEmailInfo, emailResult] = useAddEmailInfoMutation();
  const [inputFields, setInputFields] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [errors, setErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  const validateValues = (inputValues) => {
    let errors = {};
    if (inputValues.name.length < 2) {
      errors.name =  "Please enter your  name";
    }
    if (!emailRegex.test(inputValues.email)) {
      errors.email =  "Please enter a valid email address" ;
      console.log(emailRegex.test(inputValues.email))
    }
    if (!inputValues.email) {
      errors.email = "Please enter your email address";
    }
    if (inputValues.phone.length < 2) {
      errors.phone =  "Please enter your phone number";
    }
    if (!inputValues.message) {
      errors.message =  "Please enter your message ";
    }
    return errors;
  };

  const handleChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value });
  };
  const onBlur =() =>{
    setErrors(validateValues(inputFields));

  }
  const handleSubmit = (event) => {
    event.preventDefault();
    setErrors(validateValues(inputFields));
    setSubmitting(true);
  };
  useEffect(() => {
    if (emailResult.isSuccess) {
      setInputFields({
        name: "",
        email: "",
        phone: "",
        message: "",
      });
    }
  }, [emailResult.isSuccess]);

  const finishSubmit = () => {
    addEmailInfo(inputFields);
  };
  useEffect(() => {
    if (Object.keys(errors).length === 0 && submitting) {
      finishSubmit();
    }
  }, [errors]);

  const { t } = useTranslation();
  return (
    <form
      onSubmit={handleSubmit}
      onChange={handleChange}
      className="flex--col contact-us__form m--large"
    >
      {Object.keys(errors).length === 0 &&
      submitting &&
      emailResult.isSuccess ? (
        <span className="success-form">{emailResult?.data?.message} ✓</span>
      ) : null}
      {emailResult.isError && <span className="error-form">حدث خطأ</span>}
      <h3 className="algin-self--start">{t("contact.getInTouch")}</h3>
      <input
        className="contact-us__input"
        type="text"
        placeholder={t("contact.enterName")}
        name="name"
        value={inputFields.name}
        onChange={handleChange}
      />
      {errors.name ? <p className="error-form">{errors.name}</p> : null}
      <input
        className="contact-us__input"
        type="text"
        placeholder={t("contact.enterEmail")}
        name="email"
        value={inputFields.email}
        onChange={handleChange}
      />
      {errors.email ? <p className="error-form">{errors.email}</p> : null}
      <input
        className="contact-us__input"
        type="text"
        placeholder={t("contact.enterPhone")}
        name="phone"
        value={inputFields.phone}
        onChange={handleChange}
      />
      {errors.phone ? <p className="error-form">{errors.phone}</p> : null}
      <textarea
        className="contact-us__input"
        placeholder={t("contact.enterMessage")}
        value={inputFields.message}
        onChange={handleChange}
        name="message"
      ></textarea>
      {errors.message ? <p className="error-form">{errors.message}</p> : null}
      <button
        type="submit"
        className={`contact-us__btn ${
          emailResult.isLoading ? "disabled-btn" : ""
        }`}
        value={t("contact.sendMessage")}
        disabled={emailResult.isLoading ? true : false}
      >
        {t("contact.sendMessage")}
      </button>
    </form>
  );
};
