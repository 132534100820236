import { useEffect, useState } from "react";

export const Heading = ({ title, size = 1 }) => {
  const [words, setWords] = useState([]);
  useEffect(() => {
    setWords(title?.split(" "));
  }, [title]);
  return (
    <div className={`heading heading--${size}`}>
      {words?.map((word, key) => {
        return (
          <span className="heading__word" key={key}>
            {word + " "}
          </span>
        );
      })}
    </div>
  );
};
