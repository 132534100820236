import { social_link_easymedia } from "../../../data/link";
import { SVG } from "../svg/svg";

const target = "_blank";
export const SocialBox = () => {
  return (
    <div className="social-box">
      <ul className="social-box__list">
        {social_link_easymedia.map((item, key) => (
          <a href={item.link} target={target} key={key}>
            <li className="social-box__link" key={key}>
              <SVG name={item.name} />
            </li>
          </a>
        ))}
      </ul>
    </div>
  );
};
