import { useTranslation } from "react-i18next";
import { Heading } from "../global/heading/heading";
import { Paragraph } from "../global/paragraph/paragraph";

export const Header = ({ item, rev = false }) => {
  return (
    <div className="container">
      <Heading title={item.title} />
      <div className={`section ${rev ? "section--rev" : ""}`}>
        <div className="section__side ">
          <Paragraph>{item.paragraph}</Paragraph>
        </div>
        <div className="section__side">
          <img src={item.pic} alt="man" className="section__img" />
        </div>
      </div>
    </div>
  );
};
