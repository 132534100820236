import { useState } from "react";
import { BrowserRouter, Route, RouterProvider, Routes } from "react-router-dom";

//custom
import { ResponsiveMenu } from "./components/global/btns/btns";

import { Menu } from "./components/global/menu/menu";
import { Navbar } from "./components/global/navbar/navbar";
import { ScrollToTop } from "./components/global/scrollToTop/scrollToTop";
import { SocialBox } from "./components/global/social-box/socail-box";
import { HomePage } from "./pages/home/home";
import { AboutPage } from "./pages/about/about";
import { TeamPage } from "./pages/team/team";
import { ServicesPage } from "./pages/service/servicesPage";
import { WorksPage } from "./pages/works/works";
import { Privacy } from "./pages/privacy/section/privacy";
import { Project } from "./pages/project/project";
import { ContactUsPage } from "./pages/contact-us/contact-us";
import { JoinUS } from "./pages/join-us/join-us";
import "./sass/main.css";
import { router } from "./routes";
import { useTranslation } from 'react-i18next';
import store from "./RTQ/Store";
import { Provider } from "react-redux";

function App() {
  const { t, i18n } = useTranslation();

  return (
    <Provider store={store}>
    <div className="body"
      dir={i18n.language == "en" ? "ltr" : "rtl"}
      lang={i18n.language == "en" ? "en" : "ar"}
    >
      <RouterProvider router={router} />
    </div>
    </Provider>
  );
}

export default App;
