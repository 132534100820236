
import React from "react";

export const servicesData = (t) => {
  return {
    serviceData: [
      {
        title: t("serv.webDev"),
        iconName: "web-dev",
        text: t("serv.titwebDev"),
      },
      {
        title: t("serv.webApp"),
        iconName: "mobile",
        text: t("serv.titwebApp"),
      },
      {
        title: t("serv.webdesign"),
        iconName: "web-design",
        text: t("serv.titwebdesign"),
      },
      {
        title: t("serv.campaign"),
        iconName: "campaign",
        text: t("serv.titCampaign"),
      },

      {
        title: t("serv.content"),
        iconName: "content-creation",
        text: t("serv.titContent"),
      },
      {
        title: t("serv.socialmed"),
        iconName: "social",
        text: t("serv.titsocial"),
      },
      {
        title: t("serv.Marketingstrategy"),
        iconName: "marketing",
        text: t("serv.titMarketing"),
      },
      {
        title: t("serv.hostingservices"),
        iconName: "hosting",
        text: t("serv.titHosting"),
      },
      {
        title: t("serv.Branding"),
        iconName: "branding",
        text: t("serv.titBrand"),
      },
      {
        title: t("serv.voiceOver"),
        iconName: "voice",
        text: t("serv.titvoiceover"),
      },
    ],
  };
};

