import { SVG } from "../svg/svg";
import Logo from "../../../assets/logo/logo-red.svg";
import { LinksPages } from "../../../data/link";
import { NavLink } from "react-router-dom";

export const Menu = ({ setToggleMenu }) => {
  const {links} = LinksPages()
  console.log(links)
  return (
    <div className="menu menu__overlay" onClick={(e) => setToggleMenu(false)}>
      <div className="menu__content" onClick={(e) => e.stopPropagation()}>
        <div className="menu__content-logo__box">
          <img src={Logo} alt="logo" className="menu__content-logo" />
        </div>
        <div className="flex--col menu__content-links">
          {links.map((item, key) => {
            return (
              <NavLink
                key={key}
                to={item.path}
                className={({ isActive }) =>
                  isActive ? "heading heading--active" : "text-gray"
                }
              >
                {item.title}
              </NavLink>
            );
          })}
        </div>
      </div>
    </div>
  );
};
