import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { MainBtn, ThirdBtn } from "../../components/global/btns/btns";
import { Heading } from "../../components/global/heading/heading";
import { Paragraph } from "../../components/global/paragraph/paragraph";
import { ProjectHeader } from "../../containers/project/header/project";
import { Related } from "../../containers/project/related/related";
import { UI } from "../../containers/project/ui/ui";
// import { Allprojects } from "../../data/projects-data";
import { useGetAllProjectsQuery, useGetProjectQuery } from "../../RTQ/services/allApi";

const findRelated = (proj, array) => {
  return array.filter(
    (item) => item.id !== proj.id && item.category === proj.category
  );
};

export const Project = () => {
  const { id } = useParams();
  const {data} = useGetProjectQuery(id)
  const {data : Allprojects , isSuccess , isFetching , isLoading} = useGetAllProjectsQuery()
  const [related, setRelated] = useState([]);



useEffect(() => {
  const proj = Allprojects?.message?.filter((item) => item?.mainSection === data?.message?.mainSection);
  setRelated(proj);
}, [setRelated]);
  return (
    <div className="project">
      <div className="container">
        <ProjectHeader data={data?.message} />
        <UI data={data?.message} />
        {/* <Related data={related} /> */}
      </div>
    </div>
  );
};
