export const CardForm = ({ item }) => {
  return (
    <div className="contact-us__card m--auto">
      <div className="contact-us__card-icon">{item.icon}</div>
      <div className="">
        <h2 className="heading">{item.title}</h2>
        {item.desc.split("*").map((item, key) => (
          <p className="contact-us__card-p paragraph" key={key}>
            {item}
          </p>
        ))}
      </div>
    </div>
  );
};
