import { useState } from "react";
import { Link } from "react-router-dom";
//assets
import Join from "../../../assets/logo/join.png";

export const MainBtn = ({ value, path, blank = false }) => {
  return (
    <Link to={path} target={blank ? "_blank" : ""}>
      <div className="btn btn__shad">{value}</div>
    </Link>
  );
};

export const SecondaryBtn = ({ value, action, name, active }) => {
  return (
    <button
      className={`btn btn__cat ${active === name ? "btn__cat--active" : ""}`}
      onClick={action}
    >
      {value}
    </button>
  );
};

export const ThirdBtn = ({ value, action }) => {
  return (
    <button className="btn btn__cat btn__cat--radius " onClick={action}>
      {value}
    </button>
  );
};
export const JoinUsBtn = () => {
  return (
    <Link to="/join-us">
      <div className="join-us__logo">
        <img src={Join} alt="join" className="join-us__logo-img" />
      </div>
    </Link>
  );
};

export const ResponsiveMenu = ({ toggleMenu, setToggleMenu }) => {
  return (
    <span
      className="btn__responsive"
      onClick={() => setToggleMenu(!toggleMenu)}
    >
      <svg
        width="22"
        height="20"
        viewBox="0 0 22 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.75 14.2857L2.75 11.4286L22 11.4286L22 14.2857L2.75 14.2857ZM5.5 8.57143L5.5 5.71429L22 5.71429V8.57143L5.5 8.57143ZM0 2.85714L0 -4.76837e-07L22 -4.76837e-07V2.85714L0 2.85714ZM11 20V17.1429H22V20H11Z"
          fill="white"
        />
      </svg>
    </span>
  );
};
