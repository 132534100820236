import { useTranslation } from "react-i18next";

export const LinksPages = () => {
  const {t} = useTranslation()
   const links = [
    {
      title: t("nav.home"),
      path: "/",
    },
  
    {
      title: t("nav.about"),
      path: "/about-us",
    },
    {
      title: t("nav.services") ,
      path: "/services",
    },
    {
      title: t("nav.ourwork"),
      path: "/work",
    },
    {
      title: t("nav.ourteam"),
      path: "/team",
    },
  ];
  return {  links }
}


export const social_link_easymedia = [
  // {
  //   name: "facebook",
  //   link: "",
  // },
  {
    name: "instagram",
    link: "https://www.instagram.com/easymedia_agency/",
  },
  {
    name: "linked-in",
    link: "https://www.linkedin.com/company/easy-media-agency",
  },
  // {
  //   name: "youtube",
  //   link: "",
  // },
  // {
  //   name: "bechance",
  //   link: "",
  // },
  {
    name: "whats",
    link: "https://wa.link/6cx5f8",
  },
];
