import { NavLink } from "react-router-dom";
import { LinksPages } from "../../../data/link";

export const FixedLinks = () => {
  const {links} = LinksPages()
  return (
    <div className="flex--col navbar__links">
      {links.map((item, key) => {
        return (
          <NavLink
            key={key}
            to={item.path}
            className={({ isActive }) =>
              isActive ? "heading heading--active" : "text-gray"
            }
          >
            {item.title}
          </NavLink>
        );
      })}
    </div>
  );
};
