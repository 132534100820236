import { Header } from "../../components/header/header";

//assets
import PicServices from "../../assets/images/services.svg";
import { servicesData } from "./data";
import { Icons } from "./icon";
import { JoinUsBtn } from "../../components/global/btns/btns";
import { useTranslation } from "react-i18next";

export const ServicesPage = () => {

  const { t } = useTranslation();
  const headerData = {
    title: t("nav.services"),
    paragraph: t("home.desService"),
    pic: PicServices,
  };
  return (
    <div className="container">
      <Header item={headerData} />
      <ServicesContainer />
      <JoinUsBtn />
    </div>
  );
};

const ServicesContainer = () => {
  const { t } = useTranslation();

  const {serviceData} = servicesData(t)
  console.log(serviceData , "servicesData")
  return (
    <div className="flex--row">
      {serviceData?.map((item, key) => (
        <ServiceCard item={item} key={key} />
      ))}
    </div>
  );
};

const ServiceCard = ({ item }) => {
  return (
    <div className="services-card">
      <div className="services-card__content">
        <Icons name={item.iconName} />
        <h2 className="services-card-title">{item.title}</h2>
        <p className="services-card-text">{item.text}</p>
      </div>
    </div>
  );
};
