import { wrapperAPI } from "../helper/wrapperApi";

export const authApi = wrapperAPI.injectEndpoints({
  reducerPath: "authApi",
  endpoints: (builder) => ({
    register: builder.mutation({
      query: (body) => ({
        url: "user/register",
        method: "POST",
        body,
      }),
    }),
    login: builder.mutation({
      invalidatesTags: ["offers", "activeAds"],
      query: (body) => ({
        url: "user/login",
        method: "POST",
        body,
      }),
    }),
    onVerification: builder.mutation({
      query: (body) => ({
        url: "user/check-verification-code",
        method: "POST",
        body,
      }),
    }),
    resetByPhone: builder.mutation({
      query: (body) => ({
        url: "user/reset-password-by-phone",
        method: "POST",
        body,
      }),
    }),
    newPassword: builder.mutation({
      query: (body) => ({
        url: "user/action-reset-password-by-phone",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useRegisterMutation,
  useLoginMutation,
  useOnVerificationMutation,
  useResetByPhoneMutation,
  useNewPasswordMutation,
} = authApi;
