import { Heading } from "../../../components/global/heading/heading";
import { Paragraph } from "../../../components/global/paragraph/paragraph";
//importing assets
import Pic from "../../../assets/images/ready.svg";
import { useTranslation } from "react-i18next";

export const Ready = () => {
  const { t } = useTranslation();
  return (
    <div className="container">
      <div className="section ">
        <div className="section__side">
          <Heading title={t("about.ourStarted")} size={2} />
          <Paragraph>{t("about.desStarted")}</Paragraph>
        </div>
        <div className="section__side">
          <img src={Pic} alt="man" className="section__img" />
        </div>
      </div>
    </div>
  );
};
