import Tracker from "../assets/projects/triple Tracker.png";
import Bkhdmatk from "../assets/projects/bkhdmatk.png";
import BkhdmatkDash from "../assets/projects/bkhdmatk-dashboard.png";
import YoneWeb from "../assets/projects/yone-web.png";

import Yone from "../assets/projects/Yone.png";
import Appettite from "../assets/projects/Appettite restaurant.png";
import Renting from "../assets/projects/Renting.png";
import Gulf from "../assets/projects/Gulf Clinic.png";
import Kalimentan from "../assets/projects/Kalimentan.png";
import Dar from "../assets/projects/DAR.png";
import Xdogs from "../assets/projects/Xdogs.png";
import Lawer from "../assets/projects/lawer.png";
import LawerDash from "../assets/projects/lawer-dash.png";
import BkhdmatkMob from "../assets/projects/bkhdmatk-mob.png";
import KLC from "../assets/projects/KLC.png";

export const webProjects = [
  {
    id: "bkhdmatk-dashboard",
    name: "bkhdmatk dashboard",
    desc: "With bkhdmatk , the sponsor can request the services he wants for his servants, such as delivering domestic workers to the airport.",
    image: BkhdmatkDash,
    year: "2023",
    tags: ["Web Development", "Web Design", "Mobile development", "branding  "],
    screens: [],
    category: "web",
  },
  {
    id: "bkhdmatk-page",
    name: "bkhdmatk landing page",
    desc: "With bkhdmatk , the sponsor can request the services he wants for his servants, such as delivering domestic workers to the airport.",
    image: Bkhdmatk,
    year: "2023",
    tags: ["Web Development", "Web Design", "Mobile development", "branding  "],
    screens: [],
    category: "web",
  },
  {
    id: "yone-dash",
    name: "yone website",
    desc: "With bkhdmatk , the sponsor can request the services he wants for his servants, such as delivering domestic workers to the airport.",
    image: YoneWeb,
    year: "2023",
    tags: ["Web Development", "Web Design", "Mobile development", "branding  "],
    screens: [],
    category: "web",
  },
  {
    id: "dalil-almuhami-dashboard",
    name: "dalil almuhami",
    desc: "you can follow up and deliver your order with the driver to the place you want, and there is a complete management system",
    image: LawerDash,
    year: "2023",
    tags: ["Web Development", "Web Design", "Mobile development"],
    screens: [],
    category: "web",
  },
  {
    id: "triple-Tracker",
    name: "triple Tracker",
    desc: "you can follow up and deliver your order with the driver to the place you want, and there is a complete management system",
    image: Tracker,
    year: "2023",
    tags: ["Web Development", "Web Design", "Mobile development"],
    screens: [],
    category: "web",
  },
];
export const mobileProjects = [
  {
    id: "yone",
    name: "Yone",
    desc: "With Yone , the user can request the products he wants, and he can deal with his favorite stores, and filter between the products according to his.",
    image: Yone,
    year: "2023",
    tags: ["Web Development", "Web Design", "Mobile development", "branding  "],
    screens: [],
    category: "mobile",
  },
  {
    id: "dalil-almuhami",
    name: "dalil almuhami",
    desc: "With Yone , the user can request the products he wants, and he can deal with his favorite stores, and filter between the products according to his.",
    image: Lawer,
    year: "2023",
    tags: ["Web Development", "Web Design", "Mobile development", "branding  "],
    screens: [],
    category: "mobile",
  },
  {
    id: "bkhdmatk-mobile",
    name: "bkhdmatk mobile",
    desc: "With Yone , the user can request the products he wants, and he can deal with his favorite stores, and filter between the products according to his.",
    image: BkhdmatkMob,
    year: "2023",
    tags: ["Web Development", "Web Design", "Mobile development", "branding  "],
    screens: [],
    category: "mobile",
  },
];
export const socialProjects = [
  {
    id: "Xdogs",
    name: "Xdogs",
    desc: "Professionally taken photos can be tricky as each subject is different. The perfect lighting, depth, distance, colors and shadows.",
    image: Xdogs,
    year: "2023",
    tags: ["Social media"],
    screens: [],
    category: "social",
  },
  {
    id: "appettite-restaurant",
    name: "Appettite restaurant",
    desc: "Professionally taken photos can be tricky as each subject is different. The perfect lighting, depth, distance, colors and shadows.",
    image: Appettite,
    year: "2023",
    tags: ["Social media"],
    screens: [],
    category: "social",
  },
  {
    id: "Renting",
    name: "Renting",
    desc: "Professionally taken photos can be tricky as each subject is different. The perfect lighting, depth, distance, colors and shadows.",
    image: Renting,
    year: "2023",
    tags: ["Social media"],
    screens: [],
    category: "social",
  },
];
export const brandProjects = [
  {
    id: "Gulf-Clinic",
    name: "Gulf Clinic",
    desc: "Professionally taken photos can be tricky as each subject is different. The perfect lighting, depth, distance, colors and shadows.",
    image: Gulf,
    year: "2023",
    tags: ["Social media", "branding"],
    screens: [],
    category: "brand",
  },
  {
    id: "DAR",
    name: "DAR",
    desc: "Professionally taken photos can be tricky as each subject is different. The perfect lighting, depth, distance, colors and shadows.",
    image: Dar,
    year: "2023",
    tags: ["branding"],
    screens: [],
    category: "brand",
  },
  {
    id: "Kalimentan",
    name: "Kalimentan",
    desc: "Professionally taken photos can be tricky as each subject is different. The perfect lighting, depth, distance, colors and shadows.",
    image: Kalimentan,
    year: "2023",
    tags: ["branding"],
    screens: [],
    category: "brand",
  },
  {
    id: "KLC",
    name: "KLC",
    desc: "Professionally taken photos can be tricky as each subject is different. The perfect lighting, depth, distance, colors and shadows.",
    image: KLC,
    year: "2023",
    tags: ["branding"],
    screens: [],
    category: "brand",
  },
];
export const Allprojects = [
  ...webProjects,
  ...mobileProjects,
  ...socialProjects,
  ...brandProjects,
];
