import { useTranslation } from "react-i18next";
import { ThirdBtn } from "../global/btns/btns";
import { LinkTag } from "../global/link/link";
import { Paragraph } from "../global/paragraph/paragraph";

export const ProjectCard = ({ item, flex = "col" }) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const returnLang = (en, ar) => {
    if (lang === "en") {
      return en;
    } else {
      return ar;
    }
  };
  return (
    <div className={`project-card project-card--${flex} m--mid`}>
      <div className={`project__img-box--${flex}`}>
        <img
          src={item?.mainPhoto}
          className="project-img"
          alt={item?.cEnglishName}
        />
      </div>
      <div className={`project-card__details project-card__details--${flex}`}>
        <h2 className="heading heading--3">
          {returnLang(item?.cEnglishName, item?.cArabicName)}
        </h2>
        <Paragraph>
          {returnLang(
            item?.descriptionEn?.slice(0, 122) + " ,.....",
            item?.descriptionAr?.slice(0, 122) + " ,....."
          )}
        </Paragraph>
        <div className="flex--row project-cat">
          <ThirdBtn value={returnLang(item?.mainSectionEn, item?.mainSectionAr)} />
          <ThirdBtn value={returnLang(item?.subSectionEn, item?.subSectionAr)} />
        </div>
        <div className="m--mid">
          <LinkTag
            value={t("glopal.toknowMore")}
            path={`/project/${item?.workId}`}
          />
        </div>
      </div>
    </div>
  );
};
