import { JoinUsBtn } from "../../components/global/btns/btns";
import { Team } from "../../containers/team/our-team/our-team";

export const TeamPage = () => {
  return (
    <div className="container">
      <Team />
      <JoinUsBtn />
    </div>
  );
};
