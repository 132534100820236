import { useTranslation } from "react-i18next";
import { MainBtn, ThirdBtn } from "../../../components/global/btns/btns";
import { Heading } from "../../../components/global/heading/heading";
import { Paragraph } from "../../../components/global/paragraph/paragraph";

export const ProjectHeader = ({ data }) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const returnLang = (en, ar) => {
    if (lang === "en") {
      return en;
    } else {
      return ar;
    }
  };
  return (
    <div>
      <div className="flex--row ">
        <div className="flex--col algin-self--start ">
          <span className=" algin-self--start ">{t("glopal.clint")}</span>
          <Heading
            title={returnLang(data?.cEnglishName, data?.cArabicName)}
            size={2}
          />
        </div>
        <div className="flex--col align-self">
          <span>{t("glopal.year")}</span>
          <span>{data?.createdAt?.slice(0, 4)}</span>
        </div>
      </div>

      <div className="project-section flex--row m--large">
        <div className="project__main-img">
          <img
            src={data?.mainPhoto}
            className="project-img"
            alt={data?.cArabicName}
          />
        </div>

        <div className="project__info">
          <Paragraph>
            {" "}
            {returnLang(
              data?.descriptionEn,
              data?.descriptionAr
            )}
          </Paragraph>
          <div className="flex--row project-cat">
            <ThirdBtn
              value={returnLang(data?.mainSectionEn, data?.mainSectionAr)}
            />
            <ThirdBtn value= {returnLang(data?.subSectionEn, data?.subSectionAr)} />
          </div>
          <MainBtn
            value={t("glopal.visitSite")}
            path={data?.projectUrl}
            blank={true}
          />
        </div>
      </div>
    </div>
  );
};
