import { useState } from "react";
import { ResponsiveMenu } from "../components/global/btns/btns";
import { FixedLinks } from "../components/global/fixed-links/fixed-links";
import { Menu } from "../components/global/menu/menu";
import { Navbar } from "../components/global/navbar/navbar";
import { ScrollToTop } from "../components/global/scrollToTop/scrollToTop";
import { SocialBox } from "../components/global/social-box/socail-box";
import { Outlet } from "react-router-dom";

export const Layout = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <div>
      <ScrollToTop />
      <FixedLinks />
      {toggleMenu && <Menu setToggleMenu={setToggleMenu} />}
      <ResponsiveMenu setToggleMenu={setToggleMenu} toggleMenu={toggleMenu} />
      <Navbar />
      <SocialBox />
      {/* children render here */}
      <Outlet />
    </div>
  );
};
