import { useEffect, useState } from "react";
import { SVG } from "../svg/svg";
import { useTranslation } from "react-i18next";

export const EmployeeCard = ({ item }) => {
  const [pic, setPic] = useState([]);
  const { t, i18n } = useTranslation();
  console.log(i18n.language)
  useEffect(() => {
    setPic(item.inActive_pic);
  }, [item]);

  return (
    <div
      className="emp-card"
      onMouseEnter={() => setPic(item.active_pic)}
      onMouseLeave={() => setPic(item.inActive_pic)}
    >
      <div className="emp-card__content">
        <div className="emp-card__img-box">
          <img alt="emp" src={item.image} className="emp-card__img" />
        </div>
        <div className="emp-card__box flex--col">
          <p className="emp-card__name">{i18n.language === "en" ?item?.englishName :item?.arabicName }</p>
          <p className="emp-card__role">{i18n.language === "en" ?item.positionEn :item.positionAr }</p>
          {/* <div className="flex--row emp-card__social">
            {item.mail && (
              <a href={`mailto:${item.mail}`}>
                <SVG name="envelop-white" />
              </a>
            )}
            {item.bechance && (
              <a href={item.bechance}>
                <SVG name="bechance" />
              </a>
            )}

            {item.linked_in && (
              <a href={item.linked_in}>
                <SVG name="linked-in" />
              </a>
            )}

            {item.github && (
              <a href={item.github}>
                <SVG name="github" />
              </a>
            )}
            {item.website && (
              <a href={item?.website}>
                <SVG name="website" />
              </a>
            )}
          </div> */}
        </div>
      </div>
    </div>
  );
};
