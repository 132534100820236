import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const domain = "https://api.easymedia.agency/api/";
export const baseUrl = `${domain}/user`;

export const wrapperAPI = createApi({
  reducerPath: "wrapperApi",
  tagTypes: [
    "team",
    "projects"
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: domain,
    prepareHeaders: (header) => {
      header.set("Accept", `application/json`);

      // const token = localStorage.getItem("jwt");
      // if (token) {
      //   header.set("Authorization", `Bearer ${token}`);
      // }
      return header;
    },
  }),

  endpoints: () => ({}),
});
