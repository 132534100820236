import { NavLink } from "react-router-dom";
import Logo from "../../../assets/logo/logo-red.svg";
import { links_Pages } from "../../../data/link";
import { useState } from "react";
import LanguagesDropdown from "../langDropdown";

export const Navbar = () => {
  const languages = [
    {
      name: "Ar",
      code: "ar",
    },
    {
      name: "En",
      code: "en",
    },

  ];
  return (
    <div className="navbar">
      <div className="navbar__img-box">
        <img src={Logo} className="navbar__img" alt="logo" />
        <LanguagesDropdown languages={languages} />
    
      </div>
    </div>
  );
};
