import { useEffect, useState } from "react";
import { MainBtn, SecondaryBtn } from "../../components/global/btns/btns";
import { ProjectCard } from "../../components/project-card/project-card";
import {
  webProjects,
  mobileProjects,
  socialProjects,
  Allprojects,
  brandProjects,
} from "../../data/projects-data";
import { useTranslation } from "react-i18next";
import { useGetAllProjectsQuery } from "../../RTQ/services/allApi";
export const WorksPage = () => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const returnLang = (en, ar) => {
    if (lang === "en") {
      return en;
    } else {
      return ar;
    }
  };

  const { data, isSuccess } = useGetAllProjectsQuery();
  const [list, setList] = useState(data?.message);
  const uniqueCat = [
    ...new Map(
      data?.message?.map((item) => [item["mainSectionAr"], item])
    ).values(),
  ];

  const [active, setActive] = useState("");
  console.log(uniqueCat);
  useEffect(() => {
    if (isSuccess) {
      const val =  returnLang(uniqueCat[0]?.mainSectionEn, uniqueCat[0]?.mainSectionAr)
      setActive(
        val
      );
      setList(data?.message);
    }
  }, [isSuccess , lang]);

  console.log(list , "list");
  return (
    <div className="works">
      <div className="container">
        <h2 className="heading heading--2">
          {t("glopal.our")} <br />
          {t("glopal.works")}
        </h2>
        <div className="flex button-work work__btns">
          {uniqueCat?.map((item, key) => (
            <SecondaryBtn
              key={key}
              value={returnLang(item?.mainSectionEn, item?.mainSectionAr)}
              name={returnLang(item?.mainSectionEn, item?.mainSectionAr)}
              active={active}
              action={() => {
                setActive(
                  returnLang(item?.mainSectionEn, item?.mainSectionAr)
                );
                const filterTeam = data.filter(
                  (filterItem) =>
                    returnLang(
                      filterItem[0]?.mainSectionEn,
                      filterItem[0]?.mainSectionAr
                    ) ===
                    returnLang(item[0]?.mainSectionEn, item[0]?.mainSectionAr)
                );
                setList(filterTeam);
              }}
            />
          ))}
        </div>
        <div className="flex--row works__projects ">
          {list
            ?.filter(
              (fill) =>
                returnLang(fill?.mainSectionEn, fill?.mainSectionAr) === active
            )
            ?.map((item, key) => {
              return <ProjectCard item={item} />;
            })}
        </div>
        {/* <div className="m--large flex--col">
          <MainBtn value={t("glopal.viewAllProject")} path="/work" />
        </div> */}
      </div>
    </div>
  );
};

const works = [
  {
    name: "all",
    value: "All",
    list: Allprojects,
  },
  {
    name: "web",
    value: "Web development",
    list: webProjects,
  },
  {
    name: "mobile",
    value: "Mobile development",
    list: mobileProjects,
  },
  {
    name: "brand",
    value: "Branding",
    list: brandProjects,
  },
  {
    name: "social",
    value: "Social media",
    list: socialProjects,
  },
];
