import { useTranslation } from "react-i18next";
import { MainBtn } from "../global/btns/btns";
import { Heading } from "../global/heading/heading";
import { Paragraph } from "../global/paragraph/paragraph";

export const Section = ({ item, rev = false }) => {
  const {t} = useTranslation()
  return (
    <div className={`section ${rev ? "section--rev" : ""}`}>
      <div className=" section__side">
        <Heading title={item.title} size={3} />
        <Paragraph>{item.paragraph}</Paragraph>
        <MainBtn value={t("glopal.knowMore")} path={item.path} />
      </div>
      <div className="section__side">
        <img src={item.pic} alt="man" className="section__img" />
      </div>
    </div>
  );
};
