import { wrapperAPI } from "../helper/wrapperApi";

export const packagesApi = wrapperAPI.injectEndpoints({
  reducerPath: "packagesApi",
  endpoints: (builder) => ({
    getAllTeam: builder.query({
      providesTags: ["team"],
      query: () => "members/fetchmembers",
    }),

    getAllProjects: builder.query({
      providesTags: ["Packages", "activeAds"],
      query: (id) => `work/fetchworks`,
    }),
    getProject: builder.query({
      providesTags: ["Packages", "activeAds"],
      query: (id) => `/work/fetchwork/${id}`,
    }),
    addEmailInfo: builder.mutation({
      query: ({ body }) => ({
        url: `mail/sendmail`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useGetAllTeamQuery,
  useGetAllProjectsQuery,
  useGetProjectQuery,
  useAddEmailInfoMutation

} = packagesApi;
