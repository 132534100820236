import { Header } from "../../components/header/header";
import { Stats } from "../../containers/about/stats/stats";
import { Begin } from "../../containers/about/begin/begin";
import { Special } from "../../containers/about/special/special";
import { Ready } from "../../containers/about/ready/ready";

//importing assets
import Pic from "../../assets/images/about-us.svg";
import { useTranslation } from "react-i18next";
export const AboutPage = () => {
  const {t} = useTranslation()

const HeaderData = {
  title: t("nav.about"),
  paragraph:t("home.desAbout"),
  pic: Pic,
};

  return (
    <div className="container">
      <Header item={HeaderData} rev={true} />
      <Stats />
      <Begin />
      <Special />
      <Ready />
    </div>
  );
};
