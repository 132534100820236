import { Heading } from "../../components/global/heading/heading";
import { Paragraph } from "../../components/global/paragraph/paragraph";
import { IconContactus } from "./icon";

//assets
import ContactUs from "../../assets/images/contact-us.png";
import { Form } from "../../containers/contactus/form/form";
import { CardForm } from "../../containers/contactus/card-form/card-form";
import { useTranslation } from "react-i18next";

export const ContactUsPage = () => {
  const {t} = useTranslation()
  const cardsData = [
    {
      icon: <IconContactus name="location" />,
      title: t("contact.ourAddresses"),
      desc: "Kuwait : Fahad Al-Salem street * Dubai : Hamdan Bin Rashid Al Maktoum * Egypt : ismailia",
    },
    {
      icon: <IconContactus name="phone" />,
      title: t("contact.phoneNumber"),
      desc: "+965 6696 6799",
    },
    {
      icon: <IconContactus name="email" />,
      title: t("contact.email"),
      desc: "info@easymedia.agency",
    },
  ];
  
  return (
    <div className="contact-us">
      <div className="container">
        <Heading title={t("contact.contactUs")} size={2} />
        <div className="flex--row contact-us__cards m--large ">
          {cardsData.map((item, key) => (
            <CardForm item={item} key={key} />
          ))}
        </div>
        <div className="flex--row ">
          <div>
            <img src={ContactUs} alt="telephone" className="contact-us__img" />
          </div>
          <Form />
        </div>
      </div>
    </div>
  );
};

