import { useTranslation } from "react-i18next";
import { MainBtn } from "../../../components/global/btns/btns";
import { EmployeeCard } from "../../../components/global/employe-card/employe-card";
import { Heading } from "../../../components/global/heading/heading";
import { managementTeam } from "../../../data/team";
import { useGetAllTeamQuery } from "../../../RTQ/services/allApi";

export const Team = () => {
  const {t} = useTranslation()
  const {data , isSuccess , isLoading} = useGetAllTeamQuery()
  console.log(data)
  return (
    <div className="team">
      <div className="flex--col">
        <Heading title={t("nav.ourteam")} size={2} />
        <div className="flex--row team__section mt--large">
          {data?.map((item, key) => {
            if (key < 3) return <EmployeeCard item={item} key={key} />;
          })}
        </div>
        <div className="m--large">
          <MainBtn value={t("glopal.viewAllTeam")} path="/team" />
        </div>
      </div>
    </div>
  );
};
