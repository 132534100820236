import { Start } from "../../containers/home/start/start";
import { Work } from "../../containers/home/work/work";
import { Team } from "../../containers/home/team/team";
import { Section } from "../../components/section/section";

//assets
import AboutPic from "../../assets/images/about-us.svg";
import ServicesPic from "../../assets/images/services.svg";
import { JoinUsBtn } from "../../components/global/btns/btns";
import { useTranslation } from "react-i18next";

export const HomePage = () => {
  const {t} = useTranslation()
  const aboutData = {
    title: t("nav.about"),
    paragraph:  t("home.desAbout") ,
    pic: AboutPic,
    path: "/about-us",
  };
  const serviceData = {
    title: t("home.ourservices"),
    paragraph:t("home.desService"),
    pic: ServicesPic,
    path: "/services",
  };
  return (
    <div className="container">
      <Start />
      <Section item={aboutData} rev={true} />
      <Section item={serviceData} />
      <Work />
      <Team />
      <JoinUsBtn />
    </div>
  );
};
