import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { useTranslation } from "react-i18next";
import { useState } from "react";
export const Slider = (props) => {

  return (
    <div dir="ltr">
    <Carousel
      autoPlay={true}
      emulateTouch={true}
      interval={5000}

    >
      {props.children}
    </Carousel>
    </div>
  );
};
