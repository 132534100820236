import { Heading } from "../../../components/global/heading/heading";

export const Privacy = () => {
  return (
    <div className="privacy m--mid">
      <div className="container">
        <Heading title="Privacy Policy" />
      </div>
    </div>
  );
};
