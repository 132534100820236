import React, { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from 'react-i18next';

const LanguagesDropdown = ({ languages }) => {
  const [open, setOpen] = useState(false);
  const [value, setvalue] = useState(languages[0]);
  const { t, i18n } = useTranslation();

  const getValueLang = (lang) => {
    setvalue(lang);
    setOpen(false);
  };
  useEffect(() => {
    i18n.changeLanguage(value.code);

  }, [value])
  
  console.log(value)
  return (
    <div className="languages-dropdown ">
      <button onClick={() => setOpen(!open)}>
        {value.name}
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
          <path d="M12 17.414 3.293 8.707l1.414-1.414L12 14.586l7.293-7.293 1.414 1.414L12 17.414z" />
        </svg>
      </button>
      <ul
        style={{
          display: open ? "block" : "none",
        }}
      >
        {languages.map((language) => (
          <li onClick={() => getValueLang(language)} key={language.code}>
            {language.name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LanguagesDropdown;
