import { Link } from "react-router-dom";

export const LinkTag = ({ path, value }) => {
  return (
    <Link to={path}>
      <div className="link">
        <h2 className="link__name">{value}</h2>
        <Arrow />
      </div>
    </Link>
  );
};
const Arrow = () => {
  return (
    <svg
      width="35"
      height="36"
      viewBox="0 0 35 36"
      xmlns="http://www.w3.org/2000/svg"
      className="flex_link"
    >
      <path
        d="M11.4844 9.3335H25.7031V23.5522M9.29688 25.7397L25.7031 9.3335"
        stroke="white"
        strokeWidth="5.67514"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
