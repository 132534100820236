import { createBrowserRouter } from "react-router-dom";
import { Layout } from "./layout";
import { HomePage } from "./pages/home/home";
import { AboutPage } from "./pages/about/about";
import { TeamPage } from "./pages/team/team";
import { ServicesPage } from "./pages/service/servicesPage";
import { WorksPage } from "./pages/works/works";
import { Privacy } from "./pages/privacy/section/privacy";
import { Project } from "./pages/project/project";
import { ContactUsPage } from "./pages/contact-us/contact-us";
import { JoinUS } from "./pages/join-us/join-us";

export const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        element: <HomePage />,
        path: "/",
      },
      {
        element: <AboutPage />,
        path: "/about-us",
      },
      {
        element: <TeamPage />,
        path: "/team",
      },
      {
        element: <ServicesPage />,
        path: "/services",
      },
      {
        element: <WorksPage />,
        path: "/work",
      },
      {
        element: <ContactUsPage />,
        path: "/contact-us",
      },
      {
        element: <JoinUS />,
        path: "/join-us",
      },
      {
        element: <Privacy />,
        path: "/privacy-policy",
      },

      {
        element: <Project />,
        path: "/project/:id",
      },
      {
        element: <Privacy />,
        path: "/privacy-policy",
      },
    ],
  },
]);
