import { MainBtn } from "../../../components/global/btns/btns";
import { Heading } from "../../../components/global/heading/heading";
import { Paragraph } from "../../../components/global/paragraph/paragraph";

//importing assets
import Pic from "../../../assets/images/start.png";
import { useTranslation } from "react-i18next";

export const Start = () => {
  const {t} = useTranslation()
  return (
    <div className="section">
      <div className=" section__side">
        <Heading title={t("nav.home")}/>
        <Paragraph>
        {t("home.desStart")}
        </Paragraph>
        <MainBtn value={t("nav.home")} path={"/contact-us"} />
      </div>
      <div className="section__side">
        <img src={Pic} alt="man" className="section__img" />
      </div>
    </div>
  );
};
