import { MainBtn, SecondaryBtn } from "../../../components/global/btns/btns";
import { Heading } from "../../../components/global/heading/heading";
import { Paragraph } from "../../../components/global/paragraph/paragraph";

//importing assets
import { useEffect, useState } from "react";
import { Slider } from "../../../components/global/carousel/carousel";
import { ProjectCard } from "../../../components/project-card/project-card";
import {
  brandProjects,
  mobileProjects,
  socialProjects,
  webProjects,
} from "../../../data/projects-data";
import { useTranslation } from "react-i18next";
import { useGetAllProjectsQuery } from "../../../RTQ/services/allApi";
export const Work = () => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const returnLang = (en, ar) => {
    if (lang === "en") {
      return en;
    } else {
      return ar;
    }
  };

  const { data, isSuccess } = useGetAllProjectsQuery();
  const [list, setList] = useState(data?.message);
  const uniqueCat = [
    ...new Map(
      data?.message?.map((item) => [item["mainSectionAr"], item])
    ).values(),
  ];

  const [active, setActive] = useState("");
  console.log(uniqueCat);
  useEffect(() => {
    if (isSuccess) {
      const val = returnLang(
        uniqueCat[0]?.mainSectionEn,
        uniqueCat[0]?.mainSectionAr
      );
      console.log(val, "valval");
      setActive(val);
      setList(data?.message);
    }
  }, [isSuccess, lang]);

  console.log(list, "listlist");
  return (
    <div className="work">
      <div className="flex--col work__section">
        <Heading title={t("home.ourWork")} size={2} />
        <Paragraph>{t("home.desourWork")}</Paragraph>
        <div className="flex--row work__btns">
          {uniqueCat?.map((item, key) => (
            <SecondaryBtn
              key={key}
              value={returnLang(item?.mainSectionEn, item?.mainSectionAr)}
              name={returnLang(item?.mainSectionEn, item?.mainSectionAr)}
              active={active}
              action={() => {
                setActive(returnLang(item?.mainSectionEn, item?.mainSectionAr));
                const filterTeam = data.filter(
                  (filterItem) =>
                    returnLang(
                      filterItem[0]?.mainSectionEn,
                      filterItem[0]?.mainSectionAr
                    ) ===
                    returnLang(item[0]?.mainSectionEn, item[0]?.mainSectionAr)
                );
                setList(filterTeam);
              }}
            />
          ))}
        </div>
        <Slider>
          {list
            ?.filter(
              (fill) =>
                (returnLang(fill?.mainSectionEn, fill?.mainSectionAr)) ===
                active
            )
            ?.map((item, key) => {
              return (
                <div className="slider" key={key}>
                  <ProjectCard item={item} flex="row" />
                </div>
              );
            })}
        </Slider>
      </div>
      <div className="flex--col mb--mid">
        <MainBtn value={t("glopal.viewAllProject")} path="/work" />
      </div>
    </div>
  );
};

const works = [
  {
    name: "web",
    value: "Web development",
    list: webProjects,
  },
  {
    name: "mobile",
    value: "Mobile development",
    list: mobileProjects,
  },
  {
    name: "brand",
    value: "Branding",
    list: brandProjects,
  },
  {
    name: "social",
    value: "Social media",
    list: socialProjects,
  },
];
