import { Slider } from "../../../components/global/carousel/carousel";
import Pic from "../../../assets/projects/triple zero 3.png";
import { Heading } from "../../../components/global/heading/heading";
import { useTranslation } from "react-i18next";

export const UI = ({ data }) => {
  const { t } = useTranslation();
  return (
    <div className="ui">
      <div className="flex--col">
        <Heading title={t("glopal.userInterface")} size={2} />
      </div>
      <div className="m--mid ">
        <div className="flex--row ">
          {data?.userInterface?.map((item) => {
            return (
              <div className="ui__img-box">
                <img src={item} alt="project" className="ui__img" />
              </div>
            );
          })}

        </div>
      </div>
    </div>
  );
};
